/* prettier-ignore */
@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700,100italic,300italic,400italic,700italic);
/* prettier-ignore */
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);

html {
    font-size: $main-fs;
    line-height: 22px;
}
body {
    background: $main-bg;
    color: $main-text-color;
    font-family: $main-ff;
    font-size: 1rem;
    font-weight: $main-fw;
    line-height: $main-lh;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: $headers-ff;
    font-weight: $headers-fw;
    letter-spacing: 0.01em;
    margin-top: $module-rem * 2;
    margin-bottom: $module-rem * 2;
}
h1,
.h1 {
    font-size: 4.2142857rem;
    line-height: 1.13;
    margin-bottom: 3.1428572rem;
    @media only screen and (max-width: 767px) {
        font-size: 3rem;
    }
}
h2,
.h2 {
    font-size: 3.1428571rem;
    line-height: 1;
    @media only screen and (max-width: 767px) {
        font-size: 2.6rem;
    }
}
h3,
.h3 {
    font-size: 2.3571429rem;
    line-height: 1.34;
}
h4,
.h4 {
    font-size: 1.7857143rem;
    line-height: 1.32;
}
h5,
.h5 {
    font-size: 1.3571429rem;
    line-height: 1.21;
}
h6,
.h6 {
    font-size: 1rem;
    line-height: 1.6;
}
p,
ul,
ol,
pre,
table,
blockquote,
.blockquote {
    margin-top: 0;
    margin-bottom: $module-rem * 2;

    &:last-child {
        margin-bottom: 0;
    }
}
ul ul,
ol ol,
ul ol,
ol ul {
    margin-top: 0;
    margin-bottom: 0;
}
hr,
.hr {
    border: 1px solid;
    margin: -1px 0;
}
sub,
sup {
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
a {
    color: rgba(main-color(500), 0.8);
    text-decoration: none;
    transition: color 0.2s $animation;

    &:hover,
    &:focus {
        color: main-color(500);
        outline: none;
        text-decoration: none;
    }
}
img {
    height: auto;
    max-width: 100%;
}
button {
    &:focus {
        outline: none;
    }
}
.sub-title {
    display: flex;
    align-items: flex-end;
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
}
.flavor-label {
    margin-left: 0.7rem;
    font-style: italic;
}
.form-group {
    align-items: center;
}
.form-message {
    left: 0;
    margin-top: 0.2rem;
    position: absolute;
    top: 100%;
    width: 100%;
}
//Scroll
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(#000, 0.3);
}
::-webkit-scrollbar {
    background: rgba(#fff, 0.2);
    position: absolute;
    width: 4px;
}
::-webkit-scrollbar:hover {
    display: block;
}
::-webkit-scrollbar-thumb {
    background-color: main-color(700);
}
::-webkit-scrollbar:horizontal {
    height: 4px;
}
.rtl {
    th {
        text-align: right;
    }
}

.wizard {
    width: 600px;

    .mat-dialog-actions {
        //padding: 24px 48px;
        float: right;
    }
}

.my-panel {
    max-width: 27vw !important;
}

.my-panel .mat-dialog-container {
    padding: 0 !important;

    .bck {
        background: #fff;
    }

    .mat-dialog-content {
        //padding: 24px 48px;
        padding: 0;
        margin: -24px 0;
        overflow: hidden;
    }

    footer {
        padding: 0 24px;
        padding-bottom: 14px !important;
        padding-top: 14px !important;
        line-height: normal;
    }

    .row {
        margin: 0 !important;
    }
}
.strike-through {
    text-decoration: line-through;
}
