body {
    .mat-button-toggle,
    .mat-button-base,
    .mat-button,
    .mat-raised-button,
    .mat-fab,
    .mat-icon-button,
    .mat-mini-fab,
    .mat-card,
    .mat-checkbox,
    .mat-input-container,
    .mat-list,
    .mat-menu-item,
    .mat-radio-button,
    .mat-select,
    .mat-list .mat-list-item .mat-list-item-content,
    .mat-nav-list .mat-list-item .mat-list-item-content,
    .mat-simple-snackbar,
    .mat-tab-label,
    .mat-slide-toggle-content,
    .mat-toolbar,
    .mat-tooltip,
    .mat-option,
    .mat-list .mat-subheader,
    .mat-nav-list .mat-subheader {
        font-family: $main-ff !important;
    }
    /*.mat-dialog-actions {
        flex-direction: column;
    }*/
    [mat-button],
    [mat-fab],
    [mat-icon-button],
    [mat-mini-fab],
    [mat-raised-button] {
        font-weight: inherit;
    }

    .mat-tooltip {
        font-size: inherit !important;
    }

    .mat-button-toggle-label {
        margin-bottom: 0;
    }

    .mat-expansion-panel {
        box-sizing: border-box !important;
    }

    .mat-expansion-panel-header.mat-expanded {
        background: main-color(100) !important;
    }

    .mat-card {
        .mat-card-header {
            margin-bottom: $module;
        }
    }

    [mat-card-avatar] {
        overflow: hidden;
    }

    .mat-card-image {
        max-width: none;
    }

    .mat-card-title {
        margin-bottom: 0.5rem;
    }

    .mat-card-subtitle {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .mat-form-field-flex {
        align-items: center;

        .mat-form-field-prefix,
        .mat-form-field-suffix {
            padding-top: 0.84375em;
        }

        .mat-form-field-prefix {
            padding-right: $module-rem;
        }

        .mat-form-field-suffix {
            padding-left: $module-rem;
        }
    }

    .mat-form-field {
        width: 100%;

        .mat-form-field-wrapper {
            .mat-input-underline {
                bottom: 0;
                margin: 0;
            }
        }

        .mat-input-subscript-wrapper {
            padding-top: ($module-rem / 2);
            top: 100%;
        }
    }

    .mat-list {
        .mat-list-item {
            color: inherit;

            .mat-list-item-content {
                padding: 0 !important;

                .mat-list-icon {
                    font-size: 30px;
                    height: 30px;
                    width: 30px;
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-size: 1.2rem;
                    font-weight: $headers-fw;
                    line-height: 1.3;
                    overflow: hidden;
                }
            }
        }
    }

    .mat-selection-list {
        .mat-list-item {
            background-color: white;
            margin-bottom: 5px;
            height: auto;
            /*min-height: 48px;*/

            @media only screen and (max-width: 991px) {
                margin-bottom: 15px;
            }
        }

        .mat-list-item.highlighted[aria-selected="false"] {
            background: #e7e7e7;
            outline: #b5b5b5 3px double;
        }

        .mat-list-item[aria-selected="true"] {
            background-color: #b4eaff80;
            text-decoration: line-through;
        }
    }

    .mat-progress-bar,
    .mat-progress-circle,
    .mat-spinner {
        margin-bottom: $module;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .mat-radio-group {
        display: block;
        margin: 0 -11px $module;

        &:last-child {
            margin-bottom: 0;
        }

        .mat-radio-button {
            margin: 0 11px;

            .mat-radio-label {
                margin: 0;
            }
        }
    }

    .column-layout {
        .mat-radio-button {
            display: block;
            margin-bottom: $module;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .mat-checkbox {
        display: inline-block;
        margin-bottom: $module;
        vertical-align: top;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .mat-checkbox-layout {
        margin-bottom: 0;
    }

    .mat-option {
        font-size: inherit !important;
    }

    .mat-tab-label {
        .mat-icon {
            vertical-align: middle;
        }
    }

    .mat-tab-body-content {
        overflow: auto;
    }

    .mat-dialog-content {
        .mat-tab-body.mat-tab-body-active {
            max-height: 400px;
        }
    }

    .mat-icon {
        position: relative;
    }

    .sb-item-head {
        font-weight: $headers-fw;
    }

    .squeezebox {
        .sb-item {
            .sb-item-head {
                a {
                    color: rgba(main-contrast(500), 0.8);

                    .toggle-icon {
                        height: 22px;
                        width: 22px;

                        &:before,
                        &:after {
                            background: main-contrast(500);
                        }
                    }

                    &:hover,
                    &:focus {
                        color: main-contrast(500);
                    }
                }
            }
        }
    }

    .mat-input-invalid {
        .mat-input-placeholder {
            color: $error-color;
        }

        .mat-input-ripple {
            background-color: $error-color;
        }
    }

    .mat-row {
        padding: 0 $module-rem;

        &:hover {
            background-color: #2196f3;
            cursor: pointer;
        }

        .mat-cell,
        .mat-header-cell {
            padding: 0 $module-rem;
        }
    }

    .mat-row-not-hover {
        &:hover {
            background-color: transparent !important;
            cursor: default !important;
        }
    }

    .mat-header-row {
        padding: 0 $module-rem;

        .mat-cell,
        .mat-header-cell {
            padding: 0 $module-rem;
        }
    }

    .mat-paginator {
        .mat-paginator-page-size-select {
            margin: 0 4px;
            width: 56px;
        }
    }
    /*.mat-pseudo-checkbox-checked {
        &:after {
            height: 6px;
            left: 2px;
            width: 13px;
        }
    }*/

    .mat-snack-bar-container {
        background-color: #a96666;
        color: white;
        font-weight: bold;
    }
}
.rtl {
    .squeezebox .sb-item .sb-item-head a .toggle-icon {
        float: left;
    }
}
.cdk-overlay-container {
    z-index: 99999 !important;
}

.e-control,
.e-css,
.e-toolbar .e-tbar-btn {
    font-family: "Lato", sans-serif;
}

.tooltip-button {
    display: none;
}
