@import "./variable";

@import "./fonts";

@import "./structure";
@import "./typography";
@import "./helpers";
@import "./popover";
@import "./components/material-components";
@import "./components/bootstrap";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

.actions-menu {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    .mat-icon-button .mat-icon {
        line-height: normal;
    }
}

.no-arrow {
    .arrow {
        visibility: hidden;
    }
}

.divider {
    border-left: 1px solid rgba(#000, 0.2);
    display: inline-block;
    height: 18px;
    margin: 0 10px;
    vertical-align: bottom;
}

.mat-green {
    background-color: #81c784;
    color: #000000 !important;

    &:disabled {
        color: rgba(0, 0, 0, 0.26) !important;
    }
}

.mat-orange {
    background-color: #ffb74d;
    color: #000000 !important;

    &:disabled {
        color: rgba(0, 0, 0, 0.26) !important;
    }
}

button.mat-icon-button {
    height: 36px;
    line-height: 36px;
}

.mat-button-base {
    &[size="sm"] {
        line-height: 24px;
    }
}

.card-wrap {
    background-color: #fff;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid $card-header-bg;
    border-radius: 3px;
    box-shadow: 1px 1px 5px 0px rgba(main-color(500), 0.25);
    position: relative;

    &:not(.outline-card) {
        border: 0 !important;
    }

    .card-header {
        background: $card-header-bg;
        border-radius: 3px 3px 0 0;
        color: $card-header-color;
        display: block;
        margin: 0;
        padding: $module-rem $module-rem * 2;
        position: relative;

        &-title {
            display: inline-flex;
            height: 100%;
            align-items: center;
        }

        &.success-card-header {
            background: $success-color;
            color: white;
        }

        &.info-card-header {
            background: $info-color;
        }

        &.gray-card-header {
            background: $gray-color;
        }

        &.warning-card-header {
            background: $warning-color;
        }

        &.danger-card-header {
            background: $accent-color;
            color: white;
        }

        &.dead-card-header {
            background-color: main-color(500);
            color: white;
        }

        &.dark-card-header {
            background-color: main-color(200);
            color: white;
        }

        &.small-header {
            padding: 0.3rem 1.5rem;
            font-size: 1.1571429rem;
            font-weight: normal;

            .card-subtitle {
                font-size: 0.8rem;
                font-weight: bold;
                color: #a50202;
            }
        }

        &.tiny-header {
            padding: 0.3rem 1.5rem;
            font-size: 1rem;
            font-weight: normal;
        }

        + .card-content {
            border-radius: 0 0 3px 3px;
        }

        .card-subtitle {
            position: absolute;
            right: 15px;
            bottom: 0;
            margin: 0;
            line-height: normal;
        }
    }

    .card-content {
        border-radius: 3px;
        display: block;
        padding: $module-rem * 2;
        position: relative;

        &.tiny-content {
            padding: 0.3rem 1rem !important;
        }
    }

    &.success-card {
        background: $success-color;
        border-color: $success-color;

        &:not(.outline-card) {
            color: #fff;
        }
    }

    &.gray-card {
        background: $gray-color;
        border-color: $gray-color;

        &:not(.outline-card) {
            color: #fff;
        }
    }

    &.dark-card {
        background: main-color(200);
        border-color: main-color(200);
        &:not(.outline-card) {
            color: #fff;
        }
    }

    &.info-card {
        background: $info-color;
        border-color: $info-color;

        &:not(.outline-card) {
            color: #fff;
        }
    }

    &.warning-card {
        background: $warning-color;
        border-color: $warning-color;

        &:not(.outline-card) {
            color: #fff;
        }
    }

    &.danger-card {
        background: $accent-color;
        border-color: $accent-color;

        &:not(.outline-card) {
            color: #fff;
        }
    }

    &.dead-card {
        background-color: main-color(500);
        border-color: main-color(500);

        &:not(.outline-card) {
            color: #fff;
        }
    }

    &.success-color-card {
        color: $success-color;
    }

    &.info-color-card {
        color: $info-color;
    }

    &.gray-color-card {
        color: $gray-color;
    }

    &.warning-color-card {
        color: $warning-color;
    }

    &.danger-color-card {
        color: $accent-color;
    }

    &.outline-card {
        background: white !important;
        box-shadow: none !important;
    }

    &.bg-image-card {
        border: none !important;

        &:before {
            background-color: inherit;
            border-radius: 3px;
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}

.close-btn {
    .mat-button-wrapper {
        .mat-icon {
            font-size: 30px;
            width: auto;
            height: auto;
        }
    }
}

.mat-sort-header-container {
    padding-right: 25px;
    position: relative;
}

.mat-sort-header-content {
    text-align: left !important;
    display: flex;
    align-items: inherit !important;
}

.overflow-label .table-column-filter {
    top: 0px !important;
}

.customers-grid .mat-icon-button .mat-button-wrapper > * {
    vertical-align: sub !important;
}

.export-import-menu .mat-button-wrapper .mat-icon {
    font-size: 25px !important;
}

.popover {
    min-width: 350px;
}

.sub-widget {
    background: #ececec;
    padding: 10px;
}

/*Button positioning and layouts*/
.vertical-aligned-button-container {
    position: relative;
    height: 100%;

    button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }
}

.add-button-container {
    @media only screen and (max-width: 991px) {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 99999;

        button {
            border-radius: 20px;
        }
    }
}
/*End Button positioning and layouts*/

/*Tax and Fee Breakdown*/

/*Price Lookup*/
.faded-text {
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: gray;
}

.price-lookup-breakdown .d-inline-block.text-nowrap {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 10px;
    vertical-align: middle;
}

.price-lookup-breakdown .label-tax-fees > div.d-inline-block {
    margin-left: 10px !important;
}

.price-lookup-breakdown .label-tax-fees > div.d-inline-block.no-padding-label {
    margin-left: -1px !important;
}

.price-lookup-breakdown .col > div.d-inline-block {
    width: 100px;
    overflow: hidden;
    vertical-align: middle;
}

.price-lookup-breakdown .col > div.d-inline-block.text-nowrap {
    width: 150px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}

.price-lookup-breakdown .col {
    padding-right: 0px;
}

/*.price-lookup-breakdown hr {
    width: 270px;
}*/

/*END Price Lookup*/

/*Live Pricing*/

.live-pricing-container {
    color: #6c757d;
    font-size: 0.75rem;
}

.live-pricing-container {
    padding-right: 0px;
}

.live-pricing-container .col > div.d-inline-block {
    width: 60px;
    overflow: hidden;
}

.live-pricing-container .col > div.d-inline-block.text-nowrap {
    width: 100px !important;
    text-overflow: ellipsis;
    overflow: hidden;
}

.live-pricing-container .col {
    padding-right: 0px;
}

.live-pricing-container hr {
    border: 1px solid;
    margin: -1px -8px -1px -1px;
}

/*END Live Pricing*/

/*END Tax and Fee Breakdown*/

/*Customers Grid Bulk Update*/
.bulk-update .mat-form-field-hide-placeholder .mat-select-placeholder {
    color: #2196f3;
    -webkit-text-fill-color: #2196f3;
    transition: none;
    display: block;
}

.bulk-update .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #2196f3;
}

.bulk-update .mat-select-arrow {
    color: #2196f3;
}

.bulk-update .mat-form-field-appearance-legacy .mat-form-field-label {
    color: transparent;
}

.bulk-update .mat-form-field.mat-focused .mat-form-field-label {
    color: #0c7cd5;
}

.bulk-update .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #0a6ebd;
}

.bulk-update .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: #0a6ebd;
}

/*End Customers Grid Bulk Update*/

/*Dashboard Price Lookup*/

.price-checker.prices-board-item .card-wrap.collapsible .card-content {
    overflow: initial !important;
}
/*End Dashboard Price Lookup*/

.aircraft-assign-modal {
    .mat-dialog-container {
        overflow: visible;
    }
}


/* Start Customer Manager Tables */

div.mat-tab-labels .mat-tab-label.mat-tab-label-active {
    background: #ffffff !important;
    color: #252d47 !important;
    font-weight: 600 !important;
    opacity: .9;
    border-top: 3px solid #252d47;
}

.mat-tab-label {
    background: #f8f8f8;
    border-top: 1px solid #dadada;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;

}

.mat-tab-label .mat-tab-label-content {
    //opacity: .4 !important;
}

.mat-tab-label.mat-tab-label-active .mat-tab-label-content {
    opacity: .9 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar {
    background-color: transparent !important;
}

.mat-tab-header  {
  border: none;
}

.table-container.customers-grid-container {
    padding-top: 5px !important;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
}

.table-container.aircrafts-grid {
    padding-top: 0px !important;
}


body .mat-tab-body-content {
    overflow: hidden !important;
}

div.customer-aircraft-table {
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
}

/*End Customer Manager Tables*/



.price-checker {
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
}


.price-checker .mat-tab-body {
    padding-left: 15px !important;
}

.pricing-template-edit .mat-tab-body {
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
}

.pricing-template-edit .mat-tab-body {
   padding-left: 15px;
}


.pricing-template-edit .mat-tab-body-content {
    overflow: auto !important;
}

.email-template .mat-tab-body {
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    padding-left: 15px;
    padding-bottom: 15px;
}

.email-template .mat-tab-body-content {
    overflow: auto !important;
}

.statistics-tab .mat-tab-body {
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    padding-bottom: 15px;
}

.edit-customer-content .mat-tab-body {
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;

}

.edit-customer-content .mat-tab-body-content {
    overflow: auto !important;
}

.flight-watch-tabs .mat-tab-body {
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
}


/*Flight Watch map controls*/

.map-controls-button .mat-button-wrapper .mat-icon {
    font-size: 30px;
}

.is-editing .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: pointer !important
}

.mat-flat-button.mat-black {
    background-color: black;
    color: white;
}

.mat-flat-button.mat-black {
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: auto;
    padding: 0 0px;
    border-radius: 0px;
    overflow: visible;
}


.mat-flat-button.mat-black .mat-icon {
    display: inline-block;
    fill: currentColor;
    height: 33px;
    width: 38px;
}

.mat-dark-blue .mat-button-wrapper .mat-icon {
    font-size: 30px;
}

.mat-flat-button.mat-dark-blue {
    background-color: #1d497f;
    color: white;
}

.mat-flat-button.mat-dark-blue {
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: auto;
    padding: 0 0px;
    border-radius: 0px;
    overflow: visible;
}


.mat-flat-button.mat-dark-blue .mat-icon {
    display: inline-block;
    fill: currentColor;
    height: 33px;
    width: 38px;
}


.map-controls-container mat-card mat-card-title{
    background-color: #3887be;
    color: #ffffff;
    font-size: 14px;
   text-align: center;
   padding: 16px;
}

.map-controls-container mat-card {
    padding: 0px;
}

.map-controls-container .mat-card-content {
    margin-bottom: 0px;
}

.map-controls-container .mat-card > :first-child {
    margin-top: 0px;
}

body .map-controls-container .mat-card-title {
    margin-bottom: 0px;
}

.map-control-content {

    color: #686868;
    padding: 16px;
    font-size: 12px;
}


.map-controls-container .map-control-content > :first-child {
    padding-bottom: 0px;
}


.map-controls-container .map-control-content > :last-child {
    padding-bottom: 0px;
}

.map-controls-container .map-control-content div {
    padding-bottom: 5px;
}


.map-controls-container .mat-slide-toggle-bar {
    background-color: #676767;
}

.map-control-content .slide-toggle-label {
    display: inline-block;
    width: 200px;
}

.map-control-content .slide-toggle {
    display: inline-block;
}


.map-control-content .mat-slide-toggle-thumb-container {
    width: 20px;
    height: 20px;
    top: 1px;
    left: 1px;
}

.map-control-content .mat-slide-toggle-thumb-container {
    width: 20px;
    height: 20px;
    top: 1px;
    left: 1px;
}

.map-control-content .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    top: 1px;
    left: 7px;
}

.map-control-content .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
background-color: white;
}


.map-control-content .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #3887be;
}

.map-control-content .mat-slide-toggle-thumb {
    height: 12px;
    width: 12px;
    border-radius: 50%;
}

.map-controls-container .map-control-actions {
    background-color: #f7f9fa;
}

.map-control-actions {
    text-align: center;
}

.map-control-actions {
    padding: 12px;
}

.mat-flat-button.mat-blue {
    background-color: #3887be;
    color: white;
    font-size: 12px;
    font-weight: 600;
}

.map-control-actions .mat-flat-button.mat-blue {
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: auto;
    line-height: 20px;
    padding: 0 10px;
    border-radius: 0px;
    overflow: visible;
    border-radius: 2px;
    line-height: 29px;
}

.mat-flat-button.mat-cancel {
    background-color: #f7f9fa;
    font-weight: 600;
    color: #686868;
    font-size: 12px;
    border: 1px solid #686868;
}

.map-control-actions .mat-flat-button.mat-cancel {
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: auto;
    line-height: 20px;
    padding: 0 10px;
    border-radius: 0px;
    overflow: visible;
    border-radius: 2px;
    line-height: 27px;
}

.map-control-content .slide-toggle-label-on {
    display: inline-block;
    width: 200px;
    color: #3887be;
    font-weight: bold;
}


.map-control-content .mat-slide-toggle-content {
    vertical-align: top;
    display: inline-block;
    line-height: 24px;
    font-size: 7px;
    color: white;
    position: absolute;
    z-index: 1000;
}

/*End Flight Watch map controls*/
/*Flight watch settings container*/
.flight-watch-settings-container {
    color: #676767 !important;
    font-weight: 500 !important;
}

.flight-watch-settings-container .mat-card-header-text {
    margin: 0 0px !important;
}

body .mat-card.flight-watch-settings-container .mat-card-header {
    margin-bottom: 0px;
}

.flight-list-settings-button .mat-button-wrapper .mat-icon {
    font-size: 25px;
}
/*End Flight watch settings container*/
/*Map Plane Key*/


    /*div.table-container.aircrafts-grid {
    height: calc(100vh - 357px);
    min-height: 300px;
}
**/
    /** {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}*/


/*Pricing Update Generator */

.pricing-update-generator .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: .0em !important;
}


.pricing-update-generator .mat-form-field-infix {
    padding: 0.5em 0;
    border-top: 0em solid transparent;
}

.pricing-update-generator .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    margin-top: -15px;
}

.pricing-update-generator .mat-form-field-prefix {
    margin-top: -15px;
}

.pricing-update-generator .price-editor .mat-form-field-infix {
    padding: 0.7em 0;
    border-top: 0em solid transparent;
}


.pricing-update-generator .price-editor .mat-form-field-appearance-legacy .mat-form-field-label {
    top: 1.5em;
}

.pricing-update-generator .price-editor .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    margin-top: 8px;
}

.pricing-update-generator .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 1px;
    margin-bottom: -15px;
}

.mat-datepicker-popup div.actions button.mat-stroked-button,
.mat-datepicker-popup div.actions button.mat-button {
    background: #2196f3;
}

.mat-datepicker-popup div.actions .mat-button {
    color: white;
}

.mat-calendar-body-selected {
    background-color: #2196f3;
    color: white;
}

.mat-datepicker-popup div.actions button.mat-button-disabled,
.mat-datepicker-popup div.actions button.mat-button-disabled {
    background: #e1e6ea;
}

button.mat-button-disabled .mat-datepicker-popup div.actions .mat-button {
    color: #ccc3c3;
}

div.fees-taxes-button {
    position: absolute;
    right: 20px;
    top: 19px;
    z-index: 9000;
}

div.price-checker-button-bottom {
    position: absolute;
    bottom:1rem;
    right:0;
}

.distribution-toggle {
    margin-left: 217px;
}

/*Group Analytics*/
.group-analytics {
    .chart-container {
        width: 100%;

        .actions {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    table {
        width: 100%;
    }

    .table-container {
        height: 250px;
        overflow: auto;
    }

    .mat-form-field {
        font-size: 14px;
    }

    .fbos-dropdown-filter {
        .p-multiselect {
            width: 100%;
        }

        ::ng-deep {
            .p-multiselect {
                width: 30rem;

                .p-multiselect-panel {
                    max-width: 100%;
                }
            }

            .p-component {
                font-size: 13px;
            }
        }
    }

    .actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.statistics-tab {
    .chart-container {
        width: 100%;

        .actions {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    table {
        width: 100%;
    }

    .table-container {
        position: relative;
        height: 593px;
        overflow: auto;
    }

    .p-component {
        font-size: 13px;
    }

    .p-multiselect {
        min-width: 15rem;
    }
}

/*Mobile Devices*/
@media (max-width: 500px) {

    .mobile-header {
        position: absolute;
        padding-top: 10px;
        padding-left: 15px;
    }

    .ni-breadcrumb-wrap {
        display: none !important;
    }

    .open-navbar {
        height: 70px !important;
        top: 82px !important;
    }

    .distribution-header.navbar-header {
        margin-top: 15px !important;
    }

    .distribution-toggle {
        margin-left: 37px;
    }

    .addition-navbar {
        top: -20px !important;
    }
/**/

    .card-wrap .card-header.small-header {
        font-size: 14px !important;
        padding: 0.3rem .8rem !important;
    }

    .site-container .main-content .main-content-wrap {
        // border: 5px solid yellow;
        display: table;
        height: 100%;
        padding-top: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        table-layout: fixed;
        width: 100%;
    }



    h2.page-info {
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }
}

/* width */
::-webkit-scrollbar {
    width: 15px;
}
::-webkit-scrollbar:horizontal{
    height: 15px;
  }

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background:#252D47;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:horizontal{
    background:#252D47;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #252D47;
}
/* No scroll on body for custom popup */
.no-scroll {
    overflow: hidden;
}
