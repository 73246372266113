html, body {
    margin: 0;
    padding: 0;
    }
.site-container {
    position: relative;
    padding-top: $navbar-height;
    // border: 1px solid #f00;
    height: 100%;
    > * {
        display: block;
    }
    > router-outlet {
        display: none;
    }
    .main-content {
        display: relative;
        -webkit-overflow-scrolling: touch;
        height: 100%;

        .main-content-wrap {
            display: table;
            height: 100%;
            padding: 22px 55px 55px 22px;
            table-layout: fixed;
            width: 100%;
        }
    }
}
