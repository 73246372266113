@import "variable";

.overlay {
    background: $loader-overlay-bg;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 998;
}
.loader {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 999;

    span {
        animation: slide 1s infinite;
        border-radius: 50%;
        display: inline-block;
        height: 24px;
        width: 24px;

        &:nth-child(1) {
            animation-delay: 0.1s;
            background: loader-colors(1);
        }
        &:nth-child(2) {
            animation-delay: 0.2s;
            background: loader-colors(2);
        }
        &:nth-child(3) {
            animation-delay: 0.3s;
            background: loader-colors(3);
        }
        &:nth-child(4) {
            animation-delay: 0.4s;
            background: loader-colors(4);
        }
        &:nth-child(5) {
            animation-delay: 0.5s;
            background: loader-colors(5);
        }
    }
}
@keyframes slide {
    0% {
        transform: scale(1);
    }
    50% {
        opacity: 0.3;
        transform: scale(2);
    }
    100% {
        transform: scale(1);
    }
}

//loader 2
.loader-2 {
    span {
        animation: loader-2 1s infinite;
        border-radius: 0;
        height: 5px;
        width: 9px;
    }
}
@keyframes loader-2 {
    0% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(15px);
    }
    50% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(0px);
    }
}

//loader 3
$translateX: 55px;
$translateY: 57px;
$time: 2.1s;
$scale: 1.1;

.loader-3 {
    height: 100px;
    width: 100px;

    span {
        animation: loader-3 $time linear infinite;
        height: 14px;
        left: 50%;
        margin: -7px 0 0 -7px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;

        &:nth-child(1) {
            animation-delay: -$time/3;
        }
        &:nth-child(2) {
            animation-delay: -$time/1.5;
        }
        &:nth-child(3) {
            animation-delay: -$time;
        }
        &:nth-child(4) {
            display: none;
        }
        &:nth-child(5) {
            display: none;
        }
    }
}
@keyframes loader-3 {
    0% {
        transform: translate(0, 0);
    }
    12.5% {
        transform: translate(($translateX / 2), -($translateY)) scale($scale);
    }
    25% {
        transform: translate($translateX, 0);
        animation-timing-function: ease-out;
    }
    37.5% {
        transform: translate(($translateX / 2), $translateY);
    }
    50% {
        transform: translate(0, 0);
    }
    62.5% {
        transform: translate(-($translateX / 2), -($translateY)) scale($scale);
        animation-timing-function: ease-in;
    }
    75% {
        transform: translate(-($translateX), 0);
        animation-timing-function: ease-out;
    }
    87.5% {
        transform: translate(-($translateX / 2), $translateY);
    }
    100% {
        transform: translate(0, 0);
    }
}
//loader 4
.loader-4 {
    span {
        animation: loader-4 1s infinite ease-in-out;
        opacity: 0.5;

        &:nth-child(2) {
            animation-delay: -0.2s;
        }
        &:nth-child(3) {
            animation-delay: -0.4s;
        }
        &:nth-child(4) {
            animation-delay: -0.6s;
        }
        &:nth-child(5) {
            animation-delay: -0.8s;
        }
    }
}
@keyframes loader-4 {
    0% {
        opacity: 0.3;
        transform: translateY(0px);
        box-shadow: 0px 0px 3px rgba(#000, 0.1);
    }
    50% {
        opacity: 1;
        transform: translateY(-10px);
        box-shadow: 0px 20px 3px rgba(#000, 0.05);
    }
    100% {
        opacity: 0.3;
        transform: translateY(0px);
        box-shadow: 0px 0px 3px rgba(#000, 0.1);
    }
}

//loader 5
.loader-5 {
    height: 60px;
    width: 60px;

    span {
        animation: none;
        border-radius: 0;
        position: absolute;
        transform-origin: 0 0;

        &:nth-child(1) {
            animation: loader-5-1 4s infinite;
            height: 2px;
            left: 0;
            top: 0;
            width: 100%;
        }
        &:nth-child(2) {
            animation: loader-5-2 4s infinite;
            height: 100%;
            right: 0;
            top: 0;
            width: 2px;
        }
        &:nth-child(3) {
            animation: loader-5-3 4s infinite;
            bottom: 0;
            height: 2px;
            right: 0;
            transform-origin: 100% 100%;
            width: 100%;
        }
        &:nth-child(4) {
            animation: loader-5-4 4s infinite;
            bottom: 0;
            height: 100%;
            left: 0;
            transform-origin: 100% 100%;
            width: 2px;
        }
        &:nth-child(5) {
            display: none;
        }
    }
}
@keyframes loader-5-1 {
    0% {
        transform: scale(0, 1);
    }
    12.5% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 1);
    }
    62.5% {
        transform: scale(0, 1);
        transform-origin: 100% 100%;
    }
    100% {
        transform: scale(0, 1);
    }
}
@keyframes loader-5-2 {
    0% {
        transform: scale(1, 0);
    }
    12.5% {
        transform: scale(1, 0);
    }
    25% {
        transform: scale(1, 1);
        transform-origin: 0 0;
    }
    62.5% {
        transform: scale(1, 1);
    }
    75% {
        transform: scale(1, 0);
        transform-origin: 100% 100%;
    }
    100% {
        transform: scale(1, 0);
    }
}
@keyframes loader-5-3 {
    0% {
        transform: scale(0, 1);
    }
    25% {
        transform: scale(0, 1);
    }
    37.5% {
        transform: scale(1, 1);
        transform-origin: 100% 100%;
    }
    75% {
        transform: scale(1, 1);
    }
    87.5% {
        transform: scale(0, 1);
        transform-origin: 0 0;
    }
    100% {
        transform: scale(0, 1);
    }
}
@keyframes loader-5-4 {
    0% {
        transform: scale(1, 0);
    }
    37.5% {
        transform: scale(1, 0);
    }
    50% {
        transform: scale(1, 1);
        transform-origin: 100% 100%;
    }
    87.5% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 0);
        transform-origin: 0 0;
    }
}
