@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:400,400i,600,600i,700,700i|Josefin+Slab:400,400i,700,700i");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/Poppins/Poppins-Black.ttf");
}

@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/Poppins/Poppins-BlackItalic.ttf");
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/Poppins/Poppins-BoldItalic.ttf");
}

@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/Poppins/Poppins-ExtraBold.ttf");
}

@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/Poppins/Poppins-ExtraBoldItalic.ttf");
}

@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/Poppins/Poppins-ExtraLight.ttf");
}

@font-face {
    font-family: "Poppins-ExtraLightItalic";
    src: url("../fonts/Poppins/Poppins-ExtraBoldItalic.ttf");
}

@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/Poppins/Poppins-Italic.ttf");
}

@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/Poppins/Poppins-Light.ttf");
}

@font-face {
    font-family: "Poppins-LightItalic";
    src: url("../fonts/Poppins/Poppins-LightItalic.ttf");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/Poppins/Poppins-MediumItalic.ttf");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins-SemiBoldItalic";
    src: url("../fonts/Poppins/Poppins-SemiBoldItalic.ttf");
}

@font-face {
    font-family: "Poppins-Thin";
    src: url("../fonts/Poppins/Poppins-Thin.ttf");
}

@font-face {
    font-family: "Poppins-ThinItalic";
    src: url("../fonts/Poppins/Poppins-ThinItalic.ttf");
}
